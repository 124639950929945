.nwm-modal-wrapper {
    --font-color: rgb(236, 237, 238);
    --back-color: rgb(71, 73, 93);
    .ant-modal-close-x {
        border-radius: 50%;
        background: var(--back-color);
        height: 18px;
        width: 18px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--font-color);
        position: relative;
        top: 30px;
        right: 20px;
        font-size: 12px;
    }
    .ant-modal-footer {
        box-shadow: 0 -2px 10px rgba(136, 136, 136, 0.45);
        position: relative;
    }
    .ant-modal-header {
        min-height: 85px;
        display: flex;
        align-items: center;
        position: relative;
    }
    .ant-modal-body {
        position: relative;
    }
    &.title-alignment-center .ant-modal-header {
        justify-content: center;
    }
    &.title-alignment-space-between .ant-modal-header {
        justify-content: space-between;
    }
    &.title-alignment-space-around .ant-modal-header {
        justify-content: space-around;
    }
    &.title-alignment-space-evenly .ant-modal-header {
        justify-content: space-evenly;
    }
    &.title-alignment-end .ant-modal-header {
        justify-content: flex-end;
    }
    &.title-alignment-start .ant-modal-header {
        justify-content: flex-start;
    }
}
