.login-form {
    display: flex;
    flex: 1;
    flex-direction: column;
    max-width: 300px;
}

.login-form-forgot {
    float: right;
    font-weight: bold;
    color: rgb(40, 43, 50);
}

.ant-input {
    height: 40px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.ant-input:hover,
.ant-input:focus,
.ant-input:active {
    border-color: #282b32 !important;
    box-shadow: none;
}

.login-form-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(40, 43, 50) !important;
    border: 0px solid white;
    width: 100%;
    height: 40px;
}

.login-button-text {
    font-size: 22px;
}
