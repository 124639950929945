.notification-message {
    opacity: 1 !important;
    font-weight: 400 !important;
    border: 1px solid #d9d9d9 !important;
    color: black !important;
}
.form-wrapper {
    width: 596px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.button {
    margin-top: 20px;
    width: 80px !important;
}

.notification-row {
    height: 100%;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-around;
    padding: 16px;
    margin: 5px;
    border-radius: 10px;
    background: white;
}

.push-button {
    padding: 10px;
    margin: 5px;
    transition: all 0.15s ease-in-out;
    line-height: 5px;
}

.export-wrapper > div {
    margin: .85rem 0;
}